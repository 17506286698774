.pill {
  display: inline-block;
  padding: 0.35em;
  border-radius: 20px;
  margin-right: 0.25em;
  margin-bottom: 0.25em;
  transition: 0.3s all;
  line-height: 1;
  border: 1px solid lightgray;
  transition: .2s;
  cursor: default;
}

.pill:hover{
  box-shadow: 5px 5px 5px grey;
  border: 1px solid grey;
}

.pill-query {
  background: white;
  /* color: red; */
  font-size: 1em;
}

.pill-clear {
  color: white;
  cursor: pointer;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.pill-content {
  display: flex;
  font-weight: bold;
  height: 18px;
}

.padding-right {
  padding-right: 0.5em;
  padding-left: 0.5em;
}



.pill-close {
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  border: none;
  border-radius:10px;
  outline: none;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  margin-top: -0.2rem;
  transition: .2s;
}

.pill-close:hover{
  font-weight: bold;
  color: red;
}

.center-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* CSV upload */
.csv-input {
  padding: 10px;
  display: block;
  margin: 15px auto;
  border: 1px solid #ccc;
  border-radius: 5px;
}